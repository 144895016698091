// Import dependencies
import React, { useRef, useState, useEffect } from "react";
import * as tf from "@tensorflow/tfjs";
import logo from './Logo.png';
// Import required model
import * as cocossd from "@tensorflow-models/coco-ssd"
import Webcam from "react-webcam";
import "./App.css";

// Import drawing utility
import { drawRect } from "./utilities";

function App() {
  const webcamRef = useRef(null);
  const canvasRef = useRef(null);

  // Main function
  const runCoco = async () => {
    
    // Load network
    const net = await cocossd.load();

    //  Loop and detect hands
    setInterval(() => {
      detect(net);
    }, 10);
  };

  const detect = async (net) => {
    // Check data is available
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null &&
      webcamRef.current.video.readyState === 4
    ) {
      // Get Video Properties
      const video = webcamRef.current.video;
      const videoWidth = webcamRef.current.video.videoWidth;
      const videoHeight = webcamRef.current.video.videoHeight;

      // Set video width
      webcamRef.current.video.width = videoWidth;
      webcamRef.current.video.height = videoHeight;

      // Set canvas height and width
      canvasRef.current.width = videoWidth;
      canvasRef.current.height = videoHeight;

      // Make Detections    
      const obj = await net.detect(video);

      // Draw mesh
      const ctx = canvasRef.current.getContext("2d");

      // Update drawing utility
      drawRect(obj, ctx);
    }
  };

  useEffect(()=>{runCoco()},[]);

  // Change camera
  const FACING_MODE_USER = "user";
  const FACING_MODE_ENVIRONMENT = "environment";
  
  const videoConstraints = {
    facingMode: FACING_MODE_USER
  };
  
    const [facingMode, setFacingMode] = useState(FACING_MODE_USER);
  
    const handleClick = React.useCallback(() => {
      setFacingMode(
        prevState =>
          prevState === FACING_MODE_USER
            ? FACING_MODE_ENVIRONMENT
            : FACING_MODE_USER
      );
    }, []);
  

  return (
    <div className="App">
      <header>
        <img className="App-logo" src={logo}/> 
      </header>
      <div className = "App-header">
        <div className = "App-header-child">
          <button className="App-header-child-btn"onClick={handleClick}>Switch camera</button>
          <Webcam
            ref = {webcamRef}
            muted = {true} 
            videoConstraints={{
              ...videoConstraints,
              facingMode
            }}
            style = {{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              textAlign: "center",
              zindex: 9,
              width: "100%",
              height: "100%"
            }}
          />
          <canvas
            ref = {canvasRef}
            style = {{
              position: "absolute",
              marginLeft: "auto",
              marginRight: "auto",
              left: 0,
              right: 0,
              textAlign: "center",
              zindex: 8,
              width: "100%",
              height: "100%"
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
